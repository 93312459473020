<template>
  <div class="p-grid">
    <div class="p-col-12">
      <DataTable
          :autoLayout="true"
          :loading="isLoading"
          :paginator="true"
          :rowHover="true"
          :rows="15"
          :value="taskData"
          class="p-datatable-customers p-datatable-striped"
          dataKey="id"
          selection-mode="single"
          stateKey="selected-detail-report-state"
          stateStorage="session"
          @row-click="goToTask"
      >
        <template #header>
          <div class="p-d-flex p-jc-between p-flex-column">
            <div class="table-header p-col-12 p-mb-4">
              {{ tableTitle }}
            </div>

            <div class="p-d-flex p-flex-row p-jc-between">
              <div class="filters p-d-flex p-flex-row">
                <MultiSelect
                    id="engineer"
                    v-model="selectedRegions"
                    :filter="true"
                    :options="userRegions"
                    class="multiselect p-mr-4"
                    emptyFilterMessage="Совпадений не найдено"
                    optionLabel="name"
                    optionValue="uid"
                    placeholder="Филиалы"
                />
                <RangeDatePicker v-model:range="filterDate"/>
              </div>

              <div class="buttons">
                <Button
                    class="p-button-success p-mr-2"
                    @click="loadTasks"
                >
                  Сформировать отчет
                </Button>
                <Button
                    :disabled="!updatedUrl"
                    class="p-button-success p-mr-2"
                    @click="downloadReport"
                >
                  Скачать отчет
                </Button>
                <Button class="p-button-danger" @click="clearFilters">
                  Очистить фильтры
                </Button>
              </div>
            </div>
          </div>
        </template>
        <template #empty>Запчасти не найдены</template>
        <template #loading>Загружаем запчасти...</template>
        <Column
            :show-filter-menu="false"
            :sortable="true"
            field="id"
            header="Номер заявки"
        >
          <template #body="slotProps">
            {{ slotProps.data.id }}
          </template>
        </Column>
        <Column
            :showFilterMenu="false"
            :sortable="true"
            field="textDetail"
            header="Запчасти"
        >
          <template #body="slotProps">
            {{ slotProps.data.textDetail }}
          </template>
        </Column>

        <Column
            :showFilterMenu="false"
            :sortable="true"
            field="officialName"
            header="Клиент"
        >
          <template #body="slotProps">
            {{ slotProps.data.officialName }}
          </template>
        </Column>
        <Column
            :showFilterMenu="false"
            :sortable="true"
            field="region"
            header="Филиал"
        >
          <template #body="slotProps">
            {{ getCityByRegionId(slotProps.data.region) }}
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>
<script>
import RangeDatePicker from "@/views/operator/components/RangeDatePicker.vue";
import {environment} from "@/config";

export default {
  components: {RangeDatePicker},
  props: ["dateStart", "dateEnd"],
  data() {
    return {
      selectedRegions: [],
      updatedUrl: null,
      filterDate: {
        start: new Date(),
        end: new Date(),
      },
      taskData: null,
      isLoading: false,
      tableTitle: "Отчёт о дополнительных запчастях",
    };
  },
  async mounted() {
    let savedFilterData = JSON.parse(
        window.sessionStorage.getItem("taskDetails")
    );

    if (savedFilterData) {
      this.selectedRegions = savedFilterData.selectedRegions;
      this.filterDate = {
        start: new Date(savedFilterData.dateStart),
        end: new Date(savedFilterData.dateEnd)
      };
      await this.loadTasks();
    }
  },
  computed: {
    citiesMap() {
      return new Map(this.userRegions.map(obj => [obj.uid, obj.name]));
    },
    userRegions() {
      return this.$store.getters.userRegions;
    },
  },
  methods: {
    getCityByRegionId(id) {
      return this.citiesMap.get(id)
    },
    async downloadReport() {
      this.isLoading = true;

      try {
        const response = await fetch(
            `${environment.apiUrl}/reports/detail_task/excel/${this.updatedUrl}`,
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.token}`,
              },
            }
        );
        const url = window.URL.createObjectURL(await response.blob());
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Отчет о доп. запчастях.xls`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (err) {
        console.log(err);
      }

      this.isLoading = false;
    },
    goToTask(event) {
      this.$router.push(`/task/${event.data.id}`);
    },
    async loadTasks() {
      this.isLoading = true;

      try {
        this.filterDate.start.setHours(0, 0, 0, 0);
        this.filterDate.end.setHours(23, 59, 59, 0);

        if (this.selectedRegions.length === 0)
          this.selectedRegions = this.userRegions.map((r) => { return r.uid });

        this.updatedUrl =
            `?dateStart=${this.filterDate.start.toISOString()}` +
            `&dateEnd=${this.filterDate.end.toISOString()}&regionIds=${this.selectedRegions}`;

        const response = await fetch(
            `${environment.apiUrl}/reports/detail_task${this.updatedUrl}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${this.$store.getters.token}`,
                "Content-Type": "application/json",
              },
            }
        );
        this.taskData = await response.json()

        let taskFilter = {
          selectedRegions: this.selectedRegions,
          dateStart: this.filterDate.start.toISOString(),
          dateEnd: this.filterDate.end.toISOString(),
        }

        window.sessionStorage.setItem(
            "taskDetails",
            JSON.stringify(taskFilter)
        );
      } catch (e) {
        console.log(e);
      }
      this.isLoading = false;
    },
    clearFilters() {
      this.selectedRegions = [];
      this.filterDate = {
        start: new Date(),
        end: new Date(),
      };
    }
  },
}
</script>

<style scoped>
.multiselect {
  width: 190px;
  color: black;
}
</style>